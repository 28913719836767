import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen16 } from '../../../../components/images/support/xero/Screen16.generated';
import { Screen17 } from '../../../../components/images/support/xero/Screen17.generated';
import { Screen18 } from '../../../../components/images/support/xero/Screen18.generated';
import { Screen19 } from '../../../../components/images/support/xero/Screen19.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "importing-employees-from-xero-"
    }}>{`Importing employees from Xero 🔗`}</h1>
    <p>{`Follow this guide to import employees from Xero into Canyou.`}</p>
    <p>{`Before starting ensure that you have connected your Xero organisation (learn how to do that `}<a parentName="p" {...{
        "href": "/support/integrations/xero/setup"
      }}>{`here`}</a>{`).`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` You can only import employees from Xero that have a valid email address. Ensure the employee you wish to import has a valid email before starting.`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the users menu, then click the `}<strong parentName="li">{`Import from Xero`}</strong>{` button.`}</li>
    </ol>

    <Screen16 mdxType="Screen16" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Configure the settings for the employee you wish to import.`}</li>
    </ol>

    <Screen17 mdxType="Screen17" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Add`}</strong>{` button for the employee you want to import.`}</li>
    </ol>

    <Screen18 mdxType="Screen18" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`You can now see that the employee has been imported.`}</li>
    </ol>

    <Screen19 mdxType="Screen19" />
    <h1 {...{
      "id": "great-job-you-have-imported-an-employee-"
    }}>{`Great job you have imported an employee 🎉`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      